import React from "react";
import "./detail.scss";

const Detail = () => {
	return (
		<section className="full-width detail">
			<div className="grid-container">
				<div className="row">
					<div className="col-xs-12 col-md-offset-2 col-md-8 col-sm-offset-1 col-sm-10">
						<h2>Детализация</h2>
					</div>

					<div className="col-xs-12 col-md-offset-2 col-md-6 col-sm-offset-1 col-sm-10">
						<p>
							Данная услуга позволяет получить полную расшифровку поездок,
							совершенных по транспортной карте. В данный момент услуга доступна
							только организациям, приобретающим электронные проездные билеты
							для сотрудников по безналичному расчету.
						</p>

						<p>
							Воспользовавшись данной услугой, Вы будете знать точное время
							совершения поездки, вид транспорта, номер маршрута и вид
							проездного билета.
						</p>

						<p>
							Услуга предоставляется по письменному заявлению по адресу:
							г.Казань, ул.Крылова д.3
						</p>

						<p>
							При себе необходимо иметь доверенность и документ, удостоверяющий
							личность.
						</p>
					</div>
				</div>
			</div>
		</section>
	);
};

export default Detail;
